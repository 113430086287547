import loader from '../blue-loader.svg'

export default function DeployingModal() {
    return (
        <div className="AlertsModalContainer">
            <div className="LoginSignupModal__Header">
                <h3 className="LoginSignupModal__HeaderText">
                    Your Gnosis Safe is being deployed. Please standby...
                </h3>
            </div>
            <div className="LoginSignupModal__Content">
                <div className="Alerts__Body WaitingForTX">
                    <img
                        className="AssetLoading__Image"
                        src={loader}
                    />
        
                </div>
            </div>
        </div>
    )
}