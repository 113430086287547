import React, { useState, useEffect } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import copySuccess from "../copy-success.svg";

export default ({ proxyWalletAddress }) => {
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    const timeout = null;
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 5000);
    }
    return () => clearTimeout(timeout);
  }, [copied]);
  return (
    <div>
      <p className="ProfilePage__Text">
        {proxyWalletAddress}
        <CopyToClipboard
          text={proxyWalletAddress}
          onCopy={() => setCopied(true)}
        >
          <button
            className={`ProfilePage__CopyAddress ${copied ? "success" : ""}`}
          >
            {!copied && <>Copy</>}
            {copied && (
              <>
                <img src={copySuccess} /> Copied!
              </>
            )}
          </button>
        </CopyToClipboard>
      </p>
    </div>
  );
};
