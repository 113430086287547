import loader from '../blue-loader.svg'

export default function LoadingModal() {
    return (
        <div className="AlertsModalContainer">
            <div className="LoginSignupModal__Header">
                <h3 className="LoginSignupModal__HeaderText">
                    Your tokens are being transferred. Please standby...
                </h3>
            </div>
            <div className="LoginSignupModal__Content">
                <div className="Alerts__Body WaitingForTX">
                    <img
                        className="AssetLoading__Image"
                        src={loader}
                    />
                    <p>This should take about 20 seconds</p>
                </div>
            </div>
        </div>
    )
}