import { useCallback, useState, useEffect, useMemo } from "react";
import { useUserInfo } from "../context/UserContext";
import { useMainnetMagic } from "../context/MagicLinkContext";
import CopyAddress from "./CopyAddress";
import useTokenList from "../hooks/useTokenList";
import {
  coerceToSigner,
  getERC20Balance,
  sendERC20,
  sendERC20Mainnet,
  sendETH,
  sendETHMainnet,
} from "../helpers/contracts";
import { CHAIN_CONFIG, CHAIN_CURRENCY } from "../helpers/constants";
import { Web3Provider } from "@ethersproject/providers";
import { isAddress } from "@ethersproject/address";
import { Zero } from "@ethersproject/constants";
import { formatEther, formatUnits } from "@ethersproject/units";
import Logout from "./Logout";
import LoadingModal from "./LoadingModal";
import { BigNumber } from "@ethersproject/bignumber";
import { getRelayerClient } from "../utils/relayerClient";

export default function SendModal() {
  const [selectedToken, setSelectedToken] = useState(CHAIN_CURRENCY);
  const [quantity, setQuantity] = useState(Zero);

  const [destinationAddress, setDestinationAddress] = useState(null);
  const [sending, setSending] = useState(false);

  const user = useUserInfo();
  const userAddress = user.proxyWalletAddress;
  const ownerAddress = user.baseAddress;

  const magic = useMainnetMagic();
  const tokens = useTokenList();

  const [filterText, setFilterText] = useState("");

  useEffect(() => {
    setFilterText("");
  }, [tokens]);

  const options = useMemo(() => {
    if (!filterText) {
      return tokens;
    }
    return tokens.filter(
      (token) =>
        (token.symbol &&
          token.symbol
            .toLowerCase()
            .includes(String(filterText).toLowerCase())) ||
        (token.address &&
          token.address
            .toLowerCase()
            .includes(String(filterText).toLowerCase()))
    );
  }, [filterText, tokens]);

  const fetchSelectedTokenQuantity = useCallback(async () => {
    if (selectedToken) {
      let qty = Zero;
      const provider = new Web3Provider(magic.rpcProvider);
      if (selectedToken.symbol === CHAIN_CURRENCY.symbol) {
        qty = await provider.getBalance(userAddress);
      } else {
        const provider = new Web3Provider(magic.rpcProvider);
        const tokenAddress = selectedToken.address;
        const decimals = selectedToken.decimals;

        //Fetch the qty here
        qty = await getERC20Balance(
          tokenAddress,
          provider,
          userAddress,
          decimals
        );
      }
      setQuantity(qty);
    } else {
      setQuantity("");
    }
  }, [magic, selectedToken, userAddress]);

  const [signerBalance, setSignerBalance] = useState(BigNumber.from("0"));

  const fetchSignerBalance = useCallback(async () => {
    const provider = new Web3Provider(magic.rpcProvider);
    if (!provider || !ownerAddress) {
      return;
    }
    const qty = await provider.getBalance(ownerAddress);
    setSignerBalance(qty);
  }, [magic.rpcProvider, ownerAddress]);

  const [gasPrice, setGasPrice] = useState(BigNumber.from("0"));

  const fetchGasData = useCallback(async () => {
    const provider = new Web3Provider(magic.rpcProvider);
    if (!provider) {
      return;
    }
    const data = await provider.getFeeData();
    const swapUnits = "200000"; // cost to swap
    const swapGasPrice = data.maxFeePerGas
      .add(data.maxPriorityFeePerGas)
      .mul(BigNumber.from(swapUnits)); // with tip
    setGasPrice(swapGasPrice);
  }, [magic.rpcProvider]);

  useEffect(() => {
    fetchSelectedTokenQuantity();
    fetchSignerBalance();
    fetchGasData();
  }, [fetchSelectedTokenQuantity, fetchSignerBalance, fetchGasData]);

  const sendTokens = async (e) => {
    try {
      e.preventDefault();
      if (
        isAddress(destinationAddress) &&
        destinationAddress.toLowerCase() !== userAddress.toLowerCase() &&
        quantity.gt(0)
      ) {
        setSending(true);
        console.log("provider fetched");
        console.log(
          `provider: ${magic.rpcProvider}, destinationAddress: ${destinationAddress}, quantity: ${quantity}, selectedToken: ${selectedToken}`
        );
        let provider;
        let client;

        if (CHAIN_CONFIG.chainId === 1) {
          provider = new Web3Provider(magic.rpcProvider);
        } else {
          const web3Provider = new Web3Provider(await magic.wallet.getProvider());
          const signer = web3Provider.getSigner();
          client = getRelayerClient(signer, web3Provider);
        }

        const tokenAddress = selectedToken.address;

        if (selectedToken.symbol === CHAIN_CURRENCY.symbol) {
          if (CHAIN_CONFIG.chainId === 1) {
            await sendETHMainnet(provider, destinationAddress, quantity);
          } else {
            await sendETH(client, destinationAddress, quantity);
          }
        } else {
          if (CHAIN_CONFIG.chainId === 1) {
            await sendERC20Mainnet(
              tokenAddress,
              provider,
              destinationAddress,
              quantity
            );
          } else {
            await sendERC20(
              tokenAddress,
              client,
              destinationAddress,
              quantity
            );
          }
        }
        fetchSelectedTokenQuantity();
        fetchSignerBalance();
        fetchGasData();
        setSending(false);
      } else {
        alert("Address is not valid");
      }
    } catch (err) {
      setSending(false);
      console.log("Error Sending Tokens", err);
    }
  };

  if (sending) {
    return <LoadingModal />;
  }

  const notEnoughForGas = signerBalance.lt(gasPrice);
  const formattedGasPrice = formatEther(gasPrice);
  const formattedSignerBalance = formatEther(signerBalance);

  return (
    <>
      <div className="SendModal">
        <p>
          Your Polymarket Address is{" "}
          {typeof userAddress === "string" && userAddress.length > 8
            ? `${userAddress.slice(0, 8)}...`
            : userAddress}
        </p>

        <section
          style={{
            border: "1px solid red",
            padding: "16px",
            borderRadius: "8px",
          }}
        >
          <h3 style={{ color: "red", marginTop: 0 }}>
            Note: You will need {CHAIN_CURRENCY.symbol}{" "}
            to pay for gas fees associated with the transaction!
          </h3>
          <h4 style={{ marginBottom: "4px" }}>Your owner address:</h4>
          <CopyAddress proxyWalletAddress={ownerAddress} />

          <h4 style={{ marginBottom: "4px" }}>has:</h4>
          <p style={{ color: notEnoughForGas ? "red" : "green", margin: 0 }}>
            {typeof formattedSignerBalance === "string" &&
            formattedSignerBalance.length > 6
              ? formattedSignerBalance.slice(0, 8)
              : formattedSignerBalance}
            <span>{CHAIN_CURRENCY.symbol}</span>
          </p>
          <button
            className="LogoutButton reload"
            onClick={fetchSignerBalance}
            style={{ marginTop: "4px" }}
          >
            Refresh Balance
          </button>

          <h4 style={{ marginBottom: "4px" }}>You will need at least:</h4>
          <p style={{ margin: 0 }}>
            {typeof formattedGasPrice === "string" &&
            formattedGasPrice.length > 6
              ? formattedGasPrice.slice(0, 8)
              : formattedGasPrice}{" "}
            {CHAIN_CURRENCY.symbol}{" "}
            <span>in your owner address</span>
          </p>

          <button
            className="LogoutButton reload"
            onClick={fetchGasData}
            style={{ marginTop: "4px" }}
          >
            Refresh Gas Price
          </button>
        </section>

        {selectedToken && (
          <div>
            <div className="SendModal__Token">
              <img src={selectedToken.logoURI} alt={selectedToken.symbol} />
            </div>
            <div>
              You own{" "}
              {quantity
                ? formatUnits(quantity, selectedToken.decimals)
                : "0.00"}{" "}
              {selectedToken.symbol}
            </div>
            <button
              className="LogoutButton reload"
              onClick={fetchSelectedTokenQuantity}
            >
              Reload Balance
            </button>
          </div>
        )}
        {selectedToken && quantity && quantity.gt(0) && (
          <form onSubmit={sendTokens}>
            <div>
              <div className="Form__Col">
                <input
                  placeholder="Paste Recovery Address"
                  onChange={(e) => setDestinationAddress(e.target.value)}
                  value={destinationAddress}
                />
              </div>
            </div>
            <div>
              <button className="Button__Blue" disabled={notEnoughForGas}>
                {sending ? <>Sending</> : <>Send</>}
              </button>
              {notEnoughForGas ? (
                <span style={{ color: "red" }}>
                  You do not have enough {CHAIN_CURRENCY.symbol} in your owner address
                </span>
              ) : null}
            </div>
          </form>
        )}

        <div>
          <label>Select Asset</label>
          <div className="Form__Col" style={{ marginBottom: "16px" }}>
            <input
              placeholder="Find Token"
              onChange={(e) => {
                setFilterText(e.target.value);
              }}
              value={filterText}
            />
          </div>
          <div className="Options__Container">
            <div
              className={`Option ${
                selectedToken.symbol === CHAIN_CURRENCY.symbol ? "active" : ""
              }`}
              onClick={() => {
                console.log({ CHAIN_CURRENCY });
                setSelectedToken(CHAIN_CURRENCY);
              }}
              key="0x0"
            >
              <img src={CHAIN_CURRENCY.logoURI} alt={CHAIN_CURRENCY.symbol} />
              <h3>{CHAIN_CURRENCY.symbol}</h3>
            </div>

            {options.slice(0, 500).map((token) => (
              <div
                className={`Option ${
                  selectedToken.symbol === token.symbol ? "active" : ""
                }`}
                key={token.address}
                onClick={() => {
                  console.log({ token });
                  setSelectedToken(token);
                }}
              >
                <img src={token.logoURI} alt={token.symbol} />
                <h3>{token.symbol}</h3>
              </div>
            ))}
          </div>
        </div>
      </div>
      {!sending && <Logout />}
    </>
  );
}
