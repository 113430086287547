import { useMainnetMagic } from '../context/MagicLinkContext'
import { useLogout, useUserInfo } from '../context/UserContext'
import { useMMLogout } from '../context/MetamaskContext'

export default function Logout(){

    const magic = useMainnetMagic()
    const logout = useLogout()
    const mmLogout = useMMLogout();
    const user = useUserInfo();

    const handleLogout = async () => {
        try { 
            if (user.isMetamask) {
                await mmLogout;
            } else {
            await magic.user.logout();
        }
        } catch (err) {

        }
        logout()

    }
    
    return (
        <button className="LogoutButton" onClick={handleLogout}>Logout</button>
    )
}