import { initializeConnector } from "@web3-react/core";
import { WalletConnect as WalletConnectV2 } from "@web3-react/walletconnect-v2";

import { CHAIN_CONFIG } from "../../helpers/constants";

export const [walletConnectV2, walletConnectV2Hooks] = initializeConnector(
  (actions) =>
    new WalletConnectV2({
      actions,
      options: {
        projectId: process.env.REACT_APP_WALLET_CONNECT_ID,
        chains: [CHAIN_CONFIG.chainId],
        showQrModal: true,
      },
    })
);
