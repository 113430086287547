import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import MagicLinkContextProvider from "./context/MagicLinkContext";
import UserContextProvider from "./context/UserContext";
import MetamaskProvider from "./context/MetamaskContext";
import { Web3ReactProvider } from "@web3-react/core";
import { metaMask, metaMaskHooks } from "./utils/web3-react/metaMask";
import {
  walletConnectV2,
  walletConnectV2Hooks,
} from "./utils/web3-react/walletConnectV2";
import {
  coinbaseWallet,
  coinbaseWalletHooks,
} from "./utils/web3-react/coinbaseWallet";

const connectors = [
  [metaMask, metaMaskHooks],
  [walletConnectV2, walletConnectV2Hooks],
  [coinbaseWallet, coinbaseWalletHooks],
];

ReactDOM.render(
  <React.StrictMode>
    <Web3ReactProvider connectors={connectors}>
      <MagicLinkContextProvider>
        <MetamaskProvider>
          <UserContextProvider>
            <App />
          </UserContextProvider>
        </MetamaskProvider>
      </MagicLinkContextProvider>
    </Web3ReactProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
