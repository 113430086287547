import { CHAIN_CONFIG } from "../../helpers/constants";

const MATIC = {
  name: "Matic",
  symbol: "MATIC",
  decimals: 18,
};

const getAlchemyUrlForMainnet = () =>
  process.env.alchemyKey
    ? `https://eth-mainnet.alchemyapi.io/v2/${process.env.REACT_APP_ALCHEMY_DEV_KEY_MAINNET}`
    : undefined;

const getAlchemyUrlForPolygon = () =>
  process.env.alchemyKey
    ? `https://polygon-mainnet.g.alchemy.com/v2/${process.env.REACT_APP_ALCHEMY_DEV_KEY_POLYGON}`
    : undefined;

export const CHAINS = {
  1: {
    urls: [getAlchemyUrlForMainnet()].filter(Boolean),
    name: "Mainnet",
  },
  137: {
    urls: [getAlchemyUrlForPolygon()].filter(Boolean),
    name: "Polygon Mainnet",
    nativeCurrency: MATIC,
    blockExplorerUrls: ["https://polygonscan.com"],
  },
};

export const RPC_URL =
  CHAIN_CONFIG.chainId === 1
    ? getAlchemyUrlForMainnet()
    : getAlchemyUrlForPolygon();
