import { createContext, useContext } from "react";
import { useWeb3React } from "@web3-react/core";

import { CHAIN_CONFIG } from "../helpers/constants";

const MetamaskContext = createContext({});

const MetamaskProvider = ({ children }) => {
  const { connector, isActivating, isActive } = useWeb3React();

  console.log("MetamaskProvider", {
    connector,
    isActivating,
    isActive,
  });

  /**
   * Log the user out
   */
  const logoutUser = async () => {
    try {
      connector?.deactivate();
    } catch (err) {}
  };

  /**
   * Log the user in
   * @param connector
   */
  const loginUser = async (connector, connectorName) => {
    await connector.activate(CHAIN_CONFIG.chainId);
  };

  return (
    <MetamaskContext.Provider
      value={{
        logoutUser,
        loginUser,
      }}
    >
      {children}
    </MetamaskContext.Provider>
  );
};

export default MetamaskProvider;

export const useAddress = () => {
  const { account } = useWeb3React();

  return account;
};

export const useMMLogin = () => {
  const { loginUser } = useContext(MetamaskContext);

  return loginUser;
};

export const useMMLogout = () => {
  const { logoutUser } = useContext(MetamaskContext);

  return logoutUser;
};
