/* eslint-disable import/no-anonymous-default-export */
import React, {
  createContext,
  useCallback,
  useContext,
  useState,
  useMemo,
  useEffect,
} from "react";
import { AddressZero } from "@ethersproject/constants";
import { Web3Provider } from "@ethersproject/providers";

import { getProxyWalletAddress } from "@polymarket/sdk";
import { PROXY_WALLET_FACTORY_ADDRESS } from "../helpers/constants";
import { useMainnetMagic } from "./MagicLinkContext";

export const UserContext = createContext({});

const NULL_USER = {};

export default ({ children }) => {
  const [user, setUser] = useState(null);
  const [mounted, setMounted] = useState(false);
  const magic = useMainnetMagic();

  const logout = useCallback(() => setUser(NULL_USER), []);

  const loadUser = useCallback(
    async (magicRpc, walletType) => {
      try {
        const provider = new Web3Provider(magicRpc);
        console.log("provider", provider);

        const accounts = await provider.listAccounts();
        console.log("accounts", { accounts });
        const magicAddress = accounts[0] || AddressZero;
        console.log("magicAddress", magicAddress);
        const address = getProxyWalletAddress(
          PROXY_WALLET_FACTORY_ADDRESS,
          magicAddress
        );

        console.log("ACCOUNT", address);

        if (address === AddressZero) {
          throw Error("This account is zero");
        }

        setUser({
          address,
          proxyWalletAddress: address,
          baseAddress: magicAddress,
          walletType,
        });
      } catch (err) {
        console.log("Exception in loadUser", err);
        logout(null);
      }
    },
    [logout]
  );

  const loadMagicOauthUser = useCallback(
    async (magicSdk) => {
      try {
        const result = await magicSdk.oauth.getRedirectResult();
        console.log("result", { result });
        const magicAddress =
          result.magic.userMetadata.publicAddress || AddressZero;
        console.log("magicAddress", magicAddress);
        const address = getProxyWalletAddress(
          PROXY_WALLET_FACTORY_ADDRESS,
          magicAddress
        );
        console.log("proxyAddress", address);

        if (address === AddressZero) {
          throw Error("This account is zero");
        }

        setUser({
          address,
          proxyWalletAddress: address,
          baseAddress: magicAddress,
          walletType: "magic",
        });
      } catch (err) {
        console.log("Exception in loadUser", err);
        logout(null);
      }
    },
    [logout]
  );

  const loadMMUser = useCallback(async () => {
    try {
      setUser({
        isMetamask: true,
      });
    } catch (err) {
      console.log("Exception in loadMMUser", err);
      logout(null);
    }
  }, [logout]);

  useEffect(() => setMounted(true), []);

  useEffect(() => {
    if (mounted && !!magic) {
      const maybeOauthProvider = localStorage.getItem(
        "polymarket.recovery.magicOauthProvider"
      );
      if (maybeOauthProvider === "google") {
        try {
          loadMagicOauthUser(magic, "magic");
        } finally {
          localStorage.removeItem("polymarket.recovery.magicOauthProvider");
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mounted, magic]);

  const value = useMemo(
    () => ({ user, loadUser, loadMMUser, logout }),
    [user, loadUser, loadMMUser, logout]
  );
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useUserContext = () => useContext(UserContext);

export const useUserInfo = () => {
  const { user } = useUserContext();
  return user;
};

export const useLoadUser = () => {
  const { loadUser } = useUserContext();
  return loadUser;
};
export const useLoadMMUser = () => {
  const { loadMMUser } = useUserContext();
  return loadMMUser;
};

export const useLogout = () => {
  const { logout } = useUserContext();
  return logout;
};
