import React, { createContext, useContext } from "react";
import { OAuthExtension } from "@magic-ext/oauth";
import { Magic } from "magic-sdk";

import { MAGIC_KEY, CHAIN_CONFIG } from "../helpers/constants";

export const MagicLinkContext = createContext({});

const MagicLinkContextProvider = ({ children }) => {
  const mainnetMagic = new Magic(MAGIC_KEY, {
    network: {
      rpcUrl: CHAIN_CONFIG.rpcUrl,
      chainId: CHAIN_CONFIG.chainId,
    },
    extensions: [new OAuthExtension()],
  });

  return (
    <MagicLinkContext.Provider value={{ mainnetMagic }}>
      {children}
    </MagicLinkContext.Provider>
  );
};

export const useMagicLinkContext = () => useContext(MagicLinkContext);

export const useMainnetMagic = () => {
  const { mainnetMagic } = useMagicLinkContext();
  return mainnetMagic;
};

export default MagicLinkContextProvider;
