const environments = {
  mainnet: {
    networks: {
      mainnet: {
        rpcUrl:
          "https://eth-mainnet.g.alchemy.com/v2/3oM1CTm8lcxCXE45OVYFcxkBR2nj7Xue",
        chainId: 1,
      },
      matic: {
        rpcUrl:
          "https://polygon-mainnet.g.alchemy.com/v2/wwBxOtevEUo4PsWHdswCdU_xC7vF3pOk",
        chainId: 137,
      },
      base: {
        rpcUrl: "https://mainnet.base.org",
        chainId: 8453,
      },
      arbitrum: {
        rpcUrl: "https://arb1.arbitrum.io/rpc",
        chainId: 42161,
      },
      optimism: {
        rpcUrl: "https://mainnet.optimism.io",
        chainId: 10,
      },
      bnb: {
        rpcUrl: "https://bsc-dataseed.bnbchain.org",
        chainId: 56,
      },
    },
    proxyWalletFactory: "0xaB45c5A4B0c941a2F231C04C3f49182e1A254052",
  },
  mumbai: {
    networks: {
      mainnet: {
        rpcUrl: "https://goerli.infura.io/v3/562ab40b527f4075bc243e16524ac36f",
        chainId: 5,
      },
      matic: {
        rpcUrl:
          "https://polygon-mumbai.infura.io/v3/562ab40b527f4075bc243e16524ac36f",
        chainId: 80001,
      },
      base: {
        rpcUrl: "https://sepolia.base.org",
        chainId: 84532,
      },
      arbitrum: {
        rpcUrl: "https://sepolia-rollup.arbitrum.io/rpc",
        chainId: 421614,
      },
      optimism: {
        rpcUrl: "https://sepolia.optimism.io",
        chainId: 11155420,
      },
      bnb: {
        rpcUrl: "https://data-seed-prebsc-1-s1.binance.org:8545",
        chainId: 97,
      },
    },
    proxyWalletFactory: "0xaB45c5A4B0c941a2F231C04C3f49182e1A254052",
  },
};

// Pull default config for environment from `environments`.
// Each individual value can then be further overridden if necessary

const validEnvironments = Object.keys(environments);
if (!validEnvironments.includes(process.env.REACT_APP_ENVIRONMENT)) {
  throw new Error(
    `Invalid environment. Please select one of ${validEnvironments}`
  );
}
export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;
export const CHAIN_NAME = process.env.REACT_APP_CHAIN;
const VALID_CHAIN_NAMES = ["matic", "mainnet", "base", "arbitrum", "optimism", "bnb"];

if (!VALID_CHAIN_NAMES.includes(CHAIN_NAME)) {
  throw new Error(
    `Must specify one of ${VALID_CHAIN_NAMES.join(
      ","
    )} as REACT_APP_CHAIN but instead received ${CHAIN_NAME}`
  );
}

const selectedEnvironment = environments[process.env.REACT_APP_ENVIRONMENT];

export const CHAIN_CONFIG = selectedEnvironment.networks[CHAIN_NAME];

export const CHAIN_CURRENCY = (function () {
  switch (CHAIN_NAME) {
    case "mainnet":
      return {
        name: "Ethereum",
        symbol: "ETH",
        decimals: 18,
        chainId: 1,
        logoURI:
          "https://polymarket-static.s3.us-east-2.amazonaws.com/ethereum.webp",
      };
    case "base":
      return {
        name: "Ethereum",
        symbol: "ETH",
        decimals: 18,
        chainId: 8453,
        logoURI:
          "https://polymarket-static.s3.us-east-2.amazonaws.com/ethereum.webp",
      };
    case "matic":
      return {
        name: "POL",
        symbol: "POL",
        decimals: 18,
        chainId: 137,
        logoURI:
          "https://assets.coingecko.com/coins/images/32440/standard/polygon.png",
      };
    case "arbitrum":
      return {
        name: "Ethereum",
        symbol: "ETH",
        decimals: 18,
        chainId: 42161,
        logoURI:
          "https://polymarket-static.s3.us-east-2.amazonaws.com/ethereum.webp",
      };
    case "optimism":
      return {
        name: "Ethereum",
        symbol: "ETH",
        decimals: 18,
        chainId: 10,
        logoURI:
          "https://polymarket-static.s3.us-east-2.amazonaws.com/ethereum.webp",
      };
    case "bnb":
      return {
        name: "BNB",
        symbol: "BNB",
        decimals: 18,
        chainId: 56,
        logoURI:
          "https://assets.coingecko.com/coins/images/825/standard/bnb-icon2_2x.png"
      };
    default:
      throw new Error("Unknown chain name");
  }
})();

export const PROXY_WALLET_FACTORY_ADDRESS =
  process.env.PROXY_WALLET_FACTORY_ADDRESS ||
  selectedEnvironment.proxyWalletFactory;
export const RELAYER_URL = process.env.REACT_APP_RELAYER_URL ?? "";
export const MAGIC_KEY =
  process.env.REACT_APP_MAGIC_KEY || "pk_live_99ABD23F9F1C8266";
export const MAGIC_ENABLED = CHAIN_NAME === "mainnet" || CHAIN_NAME === "matic";
